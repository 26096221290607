import { graphql } from "gatsby"
import React from "react"
import { Appear, LetsTalk, Section, SEO } from "../components"
import { Page } from "../models/Page"
import { Section as SectionModel } from "../models/Section"

interface JoinPage {
  content?: SectionModel[]
  id: string
  seoMetaTags: object
  title: string
}

interface Props {
  data: {
    page: JoinPage
  }
}

const JoinPage = (props: Props & Page) => {
  const { content, seoMetaTags, title } = props.data.page

  return (
    <>
      <SEO seoMetaTags={seoMetaTags} />
      <div className="container">
        <Appear order={1}>
          <h1 className="heading-l lg:heading-xl mb-16 pr-4">{title}</h1>
        </Appear>
      </div>
      {content &&
        content.map((section, i) => {
          return <Section key={section.id} section={section} order={i} />
        })}

      <LetsTalk />
    </>
  )
}

export const query = graphql`
  query JoinPageQuery($locale: String!) {
    page: datoCmsJoin(locale: { eq: $locale }) {
      title
      content {
        __typename
        ...HeroGalleryFragment
        ...ImageFragment
        ...ImageGalleryLargeFragment
        ...JobsListingFragment
        ...StudioListFragment
        ...TextFragment
        ...Text2colFragment
        ...VideoFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`

export default JoinPage
